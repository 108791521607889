import { auth0Client, getBearerToken } from '@/plugins/auth0-client';
import { router } from '@/router';
import { SalesPortalRouteMap } from '@/router/router.config';
import axios from 'axios';
import type { AxiosInstance } from 'axios';
import qs from 'qs';
import { v4 as uuid } from 'uuid';
import { useToast } from 'vue-toastification';
import { TYPE } from 'vue-toastification/src/ts/constants';
import RequestError from '../components/toasts/RequesError.vue';

const toast = useToast();

export let salesPortalApi: AxiosInstance = null as any;

export const handleRequestError = async (error: any) => {
    switch (error?.response?.data?.statusCode) {
        case 401:
            await auth0Client.loginWithRedirect();
            break;
        case 404:
            router.push({ name: SalesPortalRouteMap.notFound.name });
            break;
        default:
            toast(RequestError, { timeout: 15000, type: TYPE.ERROR });
    }
};

export const extendAxiosClient = (baseURL: string): AxiosInstance => {
    const client = axios.create({
        baseURL,
        paramsSerializer: {
            serialize: params => qs.stringify(params, { arrayFormat: 'repeat' }),
        },
    });

    client.interceptors.request.use(async config => {
        const token = await getBearerToken();

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        config.headers['x-stream-id'] = uuid();

        return config;
    });

    client.interceptors.response.use(
        response => response,
        async error => {
            await handleRequestError(error);
            return Promise.reject(error);
        },
    );

    return client;
};

export const initSalesPortalApi = (baseURL: string) => {
    salesPortalApi = extendAxiosClient(baseURL);
};
