export enum UserState {
    SignUp = 'SignUp',
    Review = 'Review',
    Active = 'Active',
}

export enum NoAccessReason {
    UnverifiedEmail = 'UnverifiedEmail',
    MissingBasicInfo = 'MissingBasicInfo',
    AccountUnderReview = 'AccountUnderReview',
}
