import { fetchBootstrapData, getDistributionPartners, getEstate, getPartnersSellingRegions } from '@/api';
import { updateUser } from '@/api/user.api';
import { createStore } from 'vuex';

import type { ICompany, IEstate, ISalesPortalBootstrapData, ISalesPortalUserData, ISellingRegion } from '@condo/domain';

export interface State {
    estate: IEstate | null;
    requestedEstates: number[];
    user: ISalesPortalUserData | null;
    isRequestInProgress: boolean;
    isLoading: boolean;
    markedEstates: number[];
    partners: ICompany[] | null;
    sellingRegions: Array<Pick<ISellingRegion, 'name' | 'sellingRegionId' | 'purchasingRegions'>>;
    dictionaries?: ISalesPortalBootstrapData['dictionaries'];
}

// TODO: split into modules
export const store = createStore<State>({
    state() {
        return {
            estate: null,
            requestedEstates: [],
            user: null,
            isRequestInProgress: false,
            isLoading: false,
            markedEstates: [],
            partners: [],
            sellingRegions: [],
        };
    },
    getters: {
        user: state => state.user,
        isRequestInProgress: state => state.isRequestInProgress,
        isLoading: state => state.isLoading,
        markedEstates: state => state.markedEstates,
        partners: state => state.partners,
        sellingRegions: state => state.sellingRegions,
        cashflowDefaultAssumptions: state => state.dictionaries?.rootConfig.CashflowPrediction.cashflowCalculationValues,
    },
    mutations: {
        setEstate(state, estate: IEstate) {
            state.estate = estate;
        },
        setPartners(state, partners: ICompany[]) {
            state.partners = partners;
        },
        addRequestedEstate(state, estateId) {
            state.requestedEstates.push(estateId);
        },
        setUser(state, user: ISalesPortalUserData) {
            state.user = user;
            state.markedEstates = user.user_metadata?.markedEstates ?? [];
        },
        setSellingRegions(state, sellingRegions: State['sellingRegions']) {
            state.sellingRegions = sellingRegions ?? [];
        },
        toggleIsRequestInProgress(state, isRequestInProgress?: boolean) {
            if (isRequestInProgress !== undefined) {
                state.isRequestInProgress = isRequestInProgress;
            } else {
                state.isRequestInProgress = !state.isRequestInProgress;
            }
        },
        toggleIsLoading(state) {
            state.isLoading = !state.isLoading;
        },
        setIsLoading(state, isLoading: boolean) {
            state.isLoading = isLoading;
        },
        toggleEstateAsMarked(state, estateId: number) {
            const markedEstates = [...state.markedEstates];
            const existsIndex = markedEstates.indexOf(estateId);
            if (existsIndex !== -1) {
                markedEstates.splice(existsIndex, 1);
                state.markedEstates = markedEstates;
            } else {
                state.markedEstates = [...state.markedEstates, estateId];
            }
        },
        setDictionaries(state, dictionaries: ISalesPortalBootstrapData['dictionaries']) {
            state.dictionaries = dictionaries;
        },
    },
    actions: {
        async fetchEstate(context, estateId: string) {
            try {
                const estate = await getEstate(estateId);
                context.commit('setEstate', estate);
                return estate;
            } catch (err) {
                context.commit('setEstate', null);
            }
        },
        async markEstateAsFav(context, estateId: number) {
            context.commit('toggleEstateAsMarked', estateId);
            const userId = context.state?.user?.user_id;
            if (userId) {
                await updateUser(userId, { userMetadata: { markedEstates: context.state.markedEstates } });
            }
        },
        async fetchPartners(context) {
            await getDistributionPartners()
                .then(partners => context.commit('setPartners', partners))
                .catch(err => {
                    context.commit('setPartners', null);
                });
        },
        async fetchSellingRegions(context) {
            const userMetadata = context.getters?.user?.user_metadata;
            const data = await getPartnersSellingRegions({
                distributionPartnerIds: userMetadata?.distributionPartnerIds,
                canViewOpenSalesPackageEstates: userMetadata?.canViewOpenSalesPackageEstates,
            });

            const sellingRegions = data.map((r: ISellingRegion) => ({
                name: r.name,
                sellingRegionId: r.sellingRegionId,
                purchasingRegions: r.purchasingRegions ?? [],
            }));
            context.commit('setSellingRegions', sellingRegions);
        },
        async fetchBootstrapData(context): Promise<ISalesPortalBootstrapData> {
            const data = await fetchBootstrapData();

            context.commit('setDictionaries', data.dictionaries);

            return data;
        },
    },
});
