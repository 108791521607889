<template>
    <div>
        Das hat leider nicht funktioniert. <br />
        Bitte kontaktiere <a style="color: cornsilk" href="mailto:support-sales-portal@condogroup.de"><b>support-sales-portal@condogroup.de</b></a> und
        schildere dein Problem. <br />
        Vielen Dank
    </div>
</template>

<script>
export default {
    name: 'RequesError',
};
</script>
