import { salesPortalApi } from '@/plugins/api-client';

import type { ISellingRegion } from '@condo/domain';

export const getPartnersSellingRegions = async (params: {
    distributionPartnerIds?: number[];
    canViewOpenSalesPackageEstates?: boolean;
}): Promise<ISellingRegion[]> => {
    return salesPortalApi.get('/selling-regions', { params }).then(response => response.data);
};
