import { store } from '@/store';
import type { ISalesPortalTokenContent } from '@/types';
import type { Auth0VueClient } from '@auth0/auth0-vue';

export let auth0Client: Auth0VueClient = null!;

export function exposeAuth0() {
    return {
        async install(app: any) {
            auth0Client = app.config.globalProperties['$auth0'];
        },
    };
}

/**
 * Request new or get cached JWT access token
 * @returns {Promise<string>}
 */
export function getBearerToken(ignoreCache = false): Promise<string> {
    return auth0Client.getAccessTokenSilently({ authorizationParams: { scope: 'openid profile email' }, cacheMode: ignoreCache ? 'off' : 'on' });
}

/**
 * hacky way to get token data
 * @returns {Promise<Record<any, any>>}
 */
export async function getTokenData(ignoreCache = false): Promise<ISalesPortalTokenContent> {
    const data: ISalesPortalTokenContent = await getBearerToken(ignoreCache).then(() => {
        const salesPortalToken = auth0Client.user.value as ISalesPortalTokenContent;
        const currentState = salesPortalToken.salesPortalData?.app_metadata?.portalState;
        return salesPortalToken;
    });
    store.commit('setUser', data.salesPortalData);
    return data;
}
