import { REDIRECT_URL_STORAGE_KEY } from '@/const';
import { NoAccessReason, UserState } from '@/models/enums';
import { getTokenData } from '@/plugins/auth0-client';
import { trimNilOrEmptyParameters } from '@/plugins/common';
import { router } from '@/router';
import { SalesPortalRouteMap } from '@/router/router.config';
import { NavigationFailureType, type NavigationGuard, isNavigationFailure } from 'vue-router';

import { isAdminOrInternalUser } from '@condo/domain';

export const checkUserApproved: NavigationGuard = async to => {
    const { salesPortalData } = await getTokenData();
    const portalState = salesPortalData.app_metadata.portalState || salesPortalData.portalState || null;
    const savedPath = localStorage.getItem(REDIRECT_URL_STORAGE_KEY);

    if (portalState === UserState.Review) {
        return { name: 'NoAccess', query: { reason: NoAccessReason.AccountUnderReview } };
    }

    if (!portalState || portalState === UserState.SignUp) {
        return { name: 'SignUp' };
    }

    if (savedPath) {
        localStorage.removeItem(REDIRECT_URL_STORAGE_KEY);
    }

    if (savedPath && !['/sign-up', '/no-access', '/estates'].includes(savedPath)) {
        return { path: savedPath, query: to.query };
    }

    return true;
};

export const userHasAdminOrInternalRole: NavigationGuard = async () => {
    const { salesPortalData } = await getTokenData();

    if (isAdminOrInternalUser(salesPortalData)) {
        return true;
    }

    return { name: SalesPortalRouteMap['estate.list'].name };
};

/**
 * Updates the query-params for the current route, then executes a callback once its updated
 *
 * @note This method ignores the vue-router error for redundant navigations, e.g. when  navigating to the same page
 */
export const pushToQuery = (routeName: string, params: object, func?: () => void, replace = false, trimFn = trimNilOrEmptyParameters) => {
    const method = replace ? 'replace' : 'push';
    const trimmedParams = trimFn(params);

    // @ts-ignore
    return router[method]({ name: routeName, query: trimmedParams ?? {} })
        .then(() => func?.())
        .catch(createDuplicatedRouteHandler(func));
};

const createDuplicatedRouteHandler = (func?: () => void) => (err: Error) => {
    // Ignore the vue-router error regarding navigating to the page they are already on, and execute callback
    if (isNavigationFailure(err, NavigationFailureType.duplicated) || err.message?.includes('Avoided redundant navigation to current location')) {
        func?.();
    } else {
        console.error(err);
    }
};
