import { salesPortalApi } from '@/plugins/api-client';
import { store } from '@/store';

import type { IGetUsersParams, ISalesPortalAuth0AppMetadata, ISalesPortalAuth0UserMetadata, ISalesPortalUserData } from '@condo/domain';

export const updateUser = async (
    userId: string,
    userData: { appMetadata?: Partial<ISalesPortalAuth0AppMetadata>; userMetadata?: Partial<ISalesPortalAuth0UserMetadata> },
) => {
    store.commit('toggleIsRequestInProgress');
    return salesPortalApi.put(`/users/${userId}`, userData).finally(() => store.commit('toggleIsRequestInProgress', false));
};

export const getUsersList = async (params: IGetUsersParams): Promise<{ users: ISalesPortalUserData[]; total: number }> =>
    salesPortalApi.get('/users', { params }).then(resp => resp.data);
