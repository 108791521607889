import { salesPortalApi } from '@/plugins/api-client';

import type { ICompany } from '@condo/domain';

export const sendEmailsToUsers = (content: string, subject: string, partnerIds: number[]): Promise<any> => {
    return salesPortalApi.post(`/admin/emails`, { content, subject, partnerIds });
};

export const getDistributionPartners = (): Promise<ICompany[]> => {
    return salesPortalApi.get(`/distribution-partner`).then(res => res.data);
};

export const bulkUpdateSwissLife = (): Promise<{ totalUserUpdated: number }> => {
    return salesPortalApi.post(`/admin/bulk-update-swiss-life`).then(res => res.data);
};
