export interface ISalesPortalRoute {
    path: string;
    name: string;
}

export const SalesPortalRouteMap: Record<string, ISalesPortalRoute> = {
    root: { path: '/', name: 'Home' },
    notFound: { path: '/:pathMatch(.*)*', name: '404' },
    noAccess: { path: '/no-access', name: 'NoAccess' },
    signUp: { path: '/sign-up', name: 'SignUp' },
    impressum: { path: '/impressum', name: 'Impressum' },
    dataPrivacy: { path: '/data-privacy', name: 'DataPrivacy' },
    'estate.list': { path: '/estates', name: 'EstateList' },
    'estate.one': { path: '/estates/:estateId', name: 'EstateDetails' },
    'admin.user-list': { path: '/admin', name: 'UsersList' },
};
