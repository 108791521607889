import { SalesPortalRouteMap } from '@/router/router.config';
import { checkUserApproved, userHasAdminOrInternalRole } from '@/router/router.helpers';
import { authGuard } from '@auth0/auth0-vue';
import { createRouter, createWebHistory } from 'vue-router';

const getRouter = () =>
    createRouter({
        history: createWebHistory(),
        routes: [
            {
                ...SalesPortalRouteMap.root,
                component: () => import('@/pages/Home.vue'),
            },
            {
                ...SalesPortalRouteMap.noAccess,
                component: () => import('@/pages/NoAccess.vue'),
                beforeEnter: authGuard,
            },
            {
                ...SalesPortalRouteMap.signUp,
                component: () => import('@/pages/SignUp.vue'),
                beforeEnter: authGuard,
            },
            {
                ...SalesPortalRouteMap.impressum,
                component: () => import('@/pages/Impressum.vue'),
            },
            {
                ...SalesPortalRouteMap.dataPrivacy,
                component: () => import('@/pages/DataPrivacy.vue'),
            },
            {
                ...SalesPortalRouteMap['estate.list'],
                component: () => import('@/pages/EstateList.vue'),
                beforeEnter: [authGuard, checkUserApproved],
            },
            {
                ...SalesPortalRouteMap['estate.one'],
                component: () => import('@/pages/EstateDetails.vue'),
                props: true,
                beforeEnter: [authGuard, checkUserApproved],
            },
            {
                ...SalesPortalRouteMap['admin.user-list'],
                component: () => import('@/pages/admin/UsersList.vue'),
                beforeEnter: [authGuard, userHasAdminOrInternalRole],
            },
            {
                ...SalesPortalRouteMap.notFound,
                component: () => import('@/pages/NotFound.vue'),
                beforeEnter: authGuard,
            },
        ],
        scrollBehavior(to, from) {
            if (to.hash) {
                return { el: to.hash };
            }
            if (to.path !== from.path) {
                return { top: 0 };
            }
        },
    });

export const router = getRouter();
