import { isNil } from 'lodash-es';
import { sentenceCase } from 'sentence-case';
import { titleCase } from 'title-case';

import {
    DocumentType,
    ExposeMediaTags,
    ExposeMediaTagsFallbackTemplates,
    type IDocument,
    type IEstate,
    type IMediaFile,
    InternalTag,
    MediaFileCategory,
    ParkingSpaceType,
    PreDefinedTag,
    translateDocument,
} from '@condo/domain';

export const getDefaultImage = (size: string, text = 'HH-LG.de') => `https://via.placeholder.com/${size}.png?text=${text}`;

export const getPrice = (estate: IEstate): number =>
    estate?.executedSellingInvestmentCase?.expectedSalesPrice || estate?.executedInvestmentCase?.expectedSalesPrice || 0;

export const getLivingSpace = (estate: IEstate): number => estate?.postRenovationAttributes?.livingSpace || 0;

export const getRooms = (estate: IEstate) => estate?.postRenovationAttributes?.rooms || 0;

export const camelCaseToHuman = (val: string): string => (val ? titleCase(sentenceCase(val)) : '');

export const getCoordinates = (estate: IEstate) => {
    if (estate.location!.latitude && estate.location!.longitude) {
        return { lat: estate.location!.latitude, lng: estate.location!.longitude };
    }
    return undefined;
};

export const getFloorPlanPictures = (estate: IEstate): string[] => {
    if (estate.media?.length) {
        const floorPlanMedia = estate.media?.filter(
            mediaFile => !!mediaFile.internalTags?.find(tag => tag === InternalTag.FloorPlan) && mediaFile.category === MediaFileCategory.PostRenovation,
        );

        if (floorPlanMedia?.length) {
            return [floorPlanMedia.sort((a, b) => b.mediaFileId! - a.mediaFileId!)[0]].map(mf => mf.path);
        }
    }
    return [getDefaultImage('887x588', 'nicht vorhanden')];
};

// this helper function is used as a predicate to trigger case 1 or case 2 in getEstateMedia function as well as condition for getEstateSamplePictures;
export const areRealPhotosAvailable = (estate: IEstate): boolean => {
    // according to KO-6218 media file with tag expose-building does not count
    const tags = ExposeMediaTags.filter(tag => tag !== PreDefinedTag.ExposeBuilding) as string[];
    return !!estate.media?.some(m => m.tags?.some(t => tags.includes(t)));
};
export const getEstateMedia = (estate: IEstate): IMediaFile[] => {
    // sample logic discussed with Lukas
    //
    // example data-set
    //
    // pic1 in cat postRenovation [no tag]
    // pic2 in cat postRenovation [has tag-publish]
    // pic3 in cat postRenovation [has real-tag]
    // pic4 [has template tag]
    // pic5 in cat Other [has real-tag]
    // pic6 in cat postRenovation, [has tag expose-building]
    // video1 in cat postRenovation [no tag]
    //
    // case 1
    // filter all media by real tag  =>  [pic3, pic5] see if there > 0, in this case in the TAB
    // Media/Grundriss
    // ...we show here everything in PostRCategory
    // sorting  [1.st tag-publish, 2nd. ...(default sorting), 3. Expose Buildg + 4. Video]
    // [pic2, pic1, pic3, pic6, video1]
    //
    // case 2
    // filter all media by real tag  =>  [pic3, pic5] see if there === 0
    //
    // get all pictures with fallbackTemplateTags => put them in Musterfoto behind // Media/Grundriss
    // in Media/Grundriss
    // ...we show here everything with Tag Publish + ExposeBuilding + Videos (tag publish)
    // sorting  [1.st tag-publish, 3. Expose Buildg + 4. Video]
    //
    // note 1: video is sorted by getEstatePictures / getEstateVideos functions in the end in EstateDetails component
    // note 2: Musterfoto tab content is being filtered by getEstateSamplePictures function in EstateDetails component, we only show Mustefotos in case 2.

    // case 2
    if (!areRealPhotosAvailable(estate)) {
        // we still show ExposeBuilding and Videos, we also deliberately ignore PostRenovation category
        const sortedMedia = (estate.media ?? []).reduce(
            (acc, m) => {
                if (m.tags?.some(t => t === PreDefinedTag.ExposeBuilding)) {
                    acc.exposeBuildingTag.push(m);
                } else if (m.tags?.some(t => t === PreDefinedTag.Publish)) {
                    acc.publishTag.push(m);
                }
                return acc;
            },
            { publishTag: [], exposeBuildingTag: [] } as Record<string, IMediaFile[]>,
        );

        return [...sortedMedia.publishTag, ...sortedMedia.exposeBuildingTag];
    }

    // case 1
    // 100% of PostRenovation media going to be showed, but sorted accordingly
    // and additionally the one which belongs to publish, expose-building, expose-kitchen, expose-living-room, expose-bathroom, expose-room-1, expose-room-2, expose-room-3
    const unsortedMedia =
        estate.media?.filter((m: IMediaFile) => {
            return (
                m.category === MediaFileCategory.PostRenovation || m.tags?.some(t => [PreDefinedTag.Publish, ...ExposeMediaTags].includes(t as PreDefinedTag))
            );
        }) ?? [];

    // custom sorting
    // we will sort following way, first tag publish, then all kinds of expose except building, then the rest, in the end expose-building and videos
    // this will include videos as well, but they will be sorted out in the end by getEstateVideos function
    const sortedMedia = unsortedMedia.reduce(
        (acc, m) => {
            if (m.tags?.some(t => t === PreDefinedTag.Publish)) {
                acc.publish.push(m);
            } else if (m.tags?.some(t => ExposeMediaTags.includes(t as PreDefinedTag) && t !== PreDefinedTag.ExposeBuilding)) {
                acc.expose.push(m);
            } else if (m.tags?.some(t => t === PreDefinedTag.ExposeBuilding)) {
                acc.exposeBuilding.push(m);
            } else {
                acc.other.push(m);
            }
            return acc;
        },
        { publish: [], expose: [], other: [], exposeBuilding: [] } as Record<string, IMediaFile[]>,
    );

    return [...sortedMedia.publish, ...sortedMedia.expose, ...sortedMedia.other, ...sortedMedia.exposeBuilding];
};

export const getEstatePictures = (estate: IEstate): IMediaFile[] => getEstateMedia(estate).filter(m => m.mimeType.startsWith('image'));
export const getEstateVideos = (estate: IEstate): IMediaFile[] => getEstateMedia(estate).filter(m => m.mimeType.startsWith('video'));

export const getEstateSamplePictures = (estate: IEstate): IMediaFile[] => {
    if (areRealPhotosAvailable(estate)) {
        return [];
    }

    return estate.media?.filter(m => m?.tags?.some(t => ExposeMediaTagsFallbackTemplates.includes(t as PreDefinedTag))) ?? [];
};

export const getDocuments = (estate: IEstate): Partial<IDocument>[] => {
    const estateDocuments = estate?.documentRelation?.flatMap(dr => dr.document!) ?? [];

    return estateDocuments.map(document => ({
        createdAt: document.createdAt,
        documentId: document.documentId,
        filename: document.filename,
        link: document.link,
        path: document.path,
        mimeType: document.mimeType,
        documentType: document.documentType,
    }));
};

export const getKitchen = (estate: IEstate) => estate?.postRenovationAttributes?.builtInKitchen || false;

export const getToilet = (estate: IEstate) => estate?.postRenovationAttributes?.guestToilet || false;

export const exampleDocs: Partial<IDocument>[] = [
    {
        documentType: DocumentType.CondoSellingPresentation,
        mimeType: 'application/pdf',
        filename: 'CONDO_Kundenbroschu%CC%88re.pdf',
        link: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/CONDO_Kundenbroschu%CC%88re.pdf',
        path: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/CONDO_Kundenbroschu%CC%88re.pdf',
    },
    {
        documentType: DocumentType.SalesPartnerFlyer,
        mimeType: 'application/pdf',
        filename: 'CONDO_Flyer_Vertrieb.pdf',
        link: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/CONDO_%20Flyer%20Vertrieb.pdf',
        path: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/CONDO_%20Flyer%20Vertrieb.pdf',
    },
    {
        documentType: DocumentType.PropertyManagementOwnerBrochure,
        mimeType: 'application/pdf',
        filename: 'CONDO-PM_Eigentu%CC%88mer%20Onboarding%20Broschu%CC%88re.pdf',
        link: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/CONDO-PM_Eigentu%CC%88mer%20Onboarding%20Broschu%CC%88re.pdf',
        path: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/CONDO-PM_Eigentu%CC%88mer%20Onboarding%20Broschu%CC%88re.pdf',
    },
    {
        documentType: DocumentType.ModelPurchaseContract,
        mimeType: 'application/pdf',
        filename: 'MUSTER_Notariat%20Mo%CC%88nkebergstra%C3%9Fe%20-%20Kaufvertragsentwurf.pdf',
        link: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/MUSTER_Notariat%20M%C3%B6nkebergstra%C3%9Fe%20-%20Kaufvertragsentwurf.pdf',
        path: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/MUSTER_Notariat%20M%C3%B6nkebergstra%C3%9Fe%20-%20Kaufvertragsentwurf.pdf',
    },
    {
        documentType: DocumentType.ModelRentalContract,
        mimeType: 'application/pdf',
        filename: 'MUSTER_Condo%20Asset%20Management%20-%20Mietvertrag.pdf',
        link: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/MUSTER_Condo%20Asset%20Management%20-%20Mietvertrag.pdf',
        path: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/MUSTER_Condo%20Asset%20Management%20-%20Mietvertrag.pdf',
    },
    {
        documentType: DocumentType.ModelPropertyManagementContract,
        mimeType: 'application/pdf',
        filename: 'MUSTER_Condo%20Asset%20Management%20-%20SEV-Vertrag.pdf',
        link: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/MUSTER_Condo%20Asset%20Management%20-%20SEV-Vertrag.pdf',
        path: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/MUSTER_Condo%20Asset%20Management%20-%20SEV-Vertrag.pdf',
    },
    {
        documentType: DocumentType.ModelPropertyManagementLawOfAttorney,
        mimeType: 'application/pdf',
        filename: 'MUSTER_Condo%20Asset%20Management%20-%20SEV-Vollmacht.pdf',
        link: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/MUSTER_Condo%20Asset%20Management%20-%20SEV-Vollmacht.pdf',
        path: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/MUSTER_Condo%20Asset%20Management%20-%20SEV-Vollmacht.pdf',
    },
    {
        documentType: DocumentType.ModelPropertyManagementGDPR,
        mimeType: 'application/pdf',
        filename: 'MUSTER_Condo%20Asset%20Management%20-%20Datenschutz.pdf',
        link: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/MUSTER_Condo%20Asset%20Management%20-%20Datenschutz.pdf',
        path: 'https://storage.googleapis.com/condogroup-production-basecamp-uploads/MUSTER_Condo%20Asset%20Management%20-%20Datenschutz.pdf',
    },
];

export const ParkingTranslations: Record<ParkingSpaceType, string> = {
    [ParkingSpaceType.Garage]: 'Garage',
    [ParkingSpaceType.Outside]: 'Außenstellplatz',
    [ParkingSpaceType.NoInformation]: 'Ja',
    [ParkingSpaceType.StreetParking]: 'Straße',
    [ParkingSpaceType.UndergroundGarage]: 'Tiefgarage',
    [ParkingSpaceType.Carport]: 'Carport',
    [ParkingSpaceType.Duplex]: 'Duplex',
    [ParkingSpaceType.NA]: 'N/A',
    [ParkingSpaceType.CarPark]: 'Ja',
};

export const translateParkingSpaceTypes = (parkingSpaceType: ParkingSpaceType): string => ParkingTranslations[parkingSpaceType] ?? 'Ja';

export const SalesPortalDocumentTranslations: { [key in DocumentType]?: string } = {
    [DocumentType.PostRenovationDimensionedFloorPlan]: 'Bemaßter Grundriss',
    [DocumentType.ConstructionDescriptionRenovation]: 'Baubeschreibung',
    [DocumentType.LivingSpaceCalculations]: 'Wohnflächenberechnung',
    [DocumentType.BeforeAfterPictures]: 'Vorher-Nachher Bilder',
    [DocumentType.KitchenContracted]: 'Küchenunterlagen',
    [DocumentType.ProtocolArchived]: 'Protokolle',
    [DocumentType.ConstructionDescriptionBuilding]: 'Baubeschreibung Gebäude',
    [DocumentType.SalesPackageUserUploadedPriceList]: 'Kaufpreisliste',
    [DocumentType.SalesPackageUserUploadedDataSheet]: 'Datenblatt',
    [DocumentType.SalesPackageUserUploadedChecklist]: 'Checkliste',
    [DocumentType.SalesPackageUserUploadedPhotoAlbum]: 'Bildband',
    [DocumentType.SellingRegionBrochure]: 'Prospekt',
    //Example documents
    [DocumentType.CondoSellingPresentation]: 'Vertrieb - Kundenbroschüre',
    [DocumentType.SalesPartnerFlyer]: 'Vertriebspartner - Flyer',
    [DocumentType.PropertyManagementOwnerBrochure]: 'Sondereigentumsverwaltung - Eigentümerbroschüre',
    [DocumentType.ModelPurchaseContract]: 'Musterkaufvertrag',
    [DocumentType.ModelRentalContract]: 'Mustermietvertrag',
    [DocumentType.ModelPropertyManagementContract]: 'Muster-SEV-Vertrag',
    [DocumentType.ModelPropertyManagementLawOfAttorney]: 'Muster-SEV-Vollmacht',
    [DocumentType.ModelPropertyManagementGDPR]: 'Muster-SEV-Datenschutz',
};

export const salesPortalDocumentTranslations = (documentType: DocumentType): string =>
    SalesPortalDocumentTranslations[documentType] ?? translateDocument(documentType);

export const openDownloadLink = (data: Blob, fileName: string) => {
    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
};

export const trimNilOrEmptyParameters = (params = {}) => trimEmptyParameters(trimNilParameters(params));

export const trimEmptyParameters = (params = {}, fallback = undefined) =>
    Object.entries(params).reduce((acc, [key, value]) => ({ ...acc, [key]: value === '' ? fallback : value }), {});

export const trimNilParameters = (params = {}) => Object.entries(params).reduce((acc, [key, value]) => (isNil(value) ? acc : { ...acc, [key]: value }), {});
